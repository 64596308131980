<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <!-- <b-button -->
      <!--   v-if="environment()" -->
      <!--   v-ripple.400="'rgba(255, 255, 255, 0.15)'" -->
      <!--   variant="danger" -->
      <!--   class="btn-icon rounded-circle" -->
      <!--   @click="$router.push({name:'bug-report'})" -->
      <!-- > -->
      <!--   <feather-icon icon="AlertTriangleIcon" /> -->
      <!-- </b-button> -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-icon rounded-circle ml-2"
        @click="signOut"
      >
        <feather-icon icon="LogOutIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  methods: {
    signOut() {
      this.$store.dispatch('auth/LOGOUT_USER').then(() => {
        localStorage.clear()
        this.$router.push({ name: 'auth-login' })
      }).catch(this.handleError)
    },
    // environment() {
    //   return !!(typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env.VUE_APP_ENV === 'development')
    // },
  },
}
</script>

export default [
  {
    header: 'Barcode',
  },
  {
    title: 'All Barcodes',
    route: 'list-barcodes',
    icon: 'ListIcon',
  },
  {
    title: 'Registered Barcodes',
    route: 'list-registered-barcodes',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Add Barcode',
    route: 'add-barcode',
    icon: 'PlusSquareIcon',
  },
  {
    title: 'Kit Pickup Requests',
    route: 'list-barcode-pickup',
    icon: 'BookOpenIcon',
  },
  {
    title: 'View TAT',
    route: 'TAT-view',
    icon: 'UserIcon',
  },
  {
    title: 'Wet Lab Status',
    route: 'analytics-data',
    icon: 'StarIcon',
  },
  {
    header: 'Users',
  },
  {
    title: 'Consumers',
    route: 'list-consumers',
    icon: 'UsersIcon',
  },
  {
    title: 'Kit Users',
    route: 'list-kit-users',
    icon: 'UserCheckIcon',
  },
  {
    header: 'Questionnaire',
  },
  {
    title: 'All Questionnaire',
    route: 'list-questionnaire-responses',
    icon: 'BookOpenIcon',
  },
  {
    header: 'Feedback',
  },
  {
    title: 'All feedbacks',
    route: 'list-feedbacks',
    icon: 'BookOpenIcon',
  },
]

<template>
  <p class="clearfix mb-0">
    <!-- Made by <b-link to="http://deshik.in"> -->
    <!--   Deshik Labs -->
    <!-- </b-link> -->
  </p>
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
    // BLink,
  },
}
</script>
